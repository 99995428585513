<template>
  <div class="pod-prikrutiem">
    <div class="first-block">
      <picture>
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/first-block_mob.jpg`"
          media="(max-width: 767px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/first-block_tab.jpg`"
          media="(max-width: 1220px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/first-block.jpg`"
          media="(min-width: 1221px)"
        />
        <img
          class="first-block__img"
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/first-block.jpg`"/>
      </picture>
      <div class="content">
        <div class="first-block__caption">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/title_mob.svg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/title_tab.svg`"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/title.svg`"
              media="(min-width: 1221px)"
            />
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/title.svg`"
            />
          </picture>
        </div>
        <div class="first-block__description">
          Когда проблема не приходит одна…
        </div>
      </div>
    </div>
    <div class="second-block">
      <div class="content">
        <div class="second-block__description">
          На сегодняшний день одной из главных причин смертности населения
          являются сердечно-сосудистые заболевания (ССЗ), среди которых можно
          выделить ИБС, ОНМК, а также ХСН, как финал практических всех
          ССЗ<sup>1,2</sup>.
          <br />
          <br />
          Кроме того, часто симптомы ХСН могут «маскироваться» под клинику
          других заболеваний, что может потребовать от врача более расширенного
          списка диагностических обследований пациента с ССЗ<sup>2</sup>.
          <br />
          <br />
          Часто в клинической практике у пациента встречается сочетанная
          патология со стороны сердечно-сосудистой системы<sup>3</sup>.
          <br />
          <br />
          Например, распространенность ХСН у пациентов, перенесших эпизод
          острого ИМ составляет 24%<sup>4,5</sup>, а 48% пациентов, перенесших
          ОКС, имели в анамнезе ХСН<sup>6</sup>.
          <br />
          <br />
          Наличие нескольких заболеваний может значимо ухудшить прогноз,
          увеличить риск неблагоприятных исходов и затраты на медицинскую
          помощь<sup>1</sup>
        </div>
      </div>
    </div>
    <div class="statistic-block">
      <div class="content">
        <div class="statistic-block__title">
          Каждую минуту в России
          <span class="statistic-block__title_highlight">26</span>&nbsp;новых
          случаев инсульта, в&nbsp;том числе и с ХСН
        </div>
        <video
          class="statistic-block__video"
          autoplay
          muted
          loop
          playsinline
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/pod_prikrutiem/heart.webm`"
        ></video>
        <div class="statistic-block__data">
          <div class="statistic-block__data-item">
            <div class="statistic-block__data-item-value">
              {{ animValues[0].value.toFixed(1).replace(".", ",") }}
            </div>
            <div class="statistic-block__data-item-label">млн в год</div>
          </div>
          <div class="statistic-block__data-item">
            <div class="statistic-block__data-item-value">
              {{ animValues[1].value.toFixed(0) }}
            </div>
            <div class="statistic-block__data-item-label">тыс в день</div>
          </div>
          <div class="statistic-block__data-item">
            <div class="statistic-block__data-item-value">
              {{ animValues[2].value.toFixed(0) }}
            </div>
            <div class="statistic-block__data-item-label">в минуту</div>
          </div>
        </div>

        <div class="statistic-block__link">
          Не упустите их на приеме!
        </div>
      </div>
    </div>
    <div class="diagnostic-block">
      <div class="content">
        <picture>
          <source
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/block4_tab.jpg`"
            media="(max-width: 1220px)"
          />
          <img
            class="diagnostic-block__image"
            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/block4_tab.jpg`"
          />
        </picture>
        <div class="diagnostic-block__title">Диагностика</div>
        <div class="diagnostic-block__description">
          ХСН  представляет собой хроническое прогрессирующее заболевание,
          которое  может значительно снижать качество жизни. Наиболее типичными
          симптомами и признаками заболевания являются: одышка, ортопное,
          снижение толерантности к физической нагрузке, повышенная утомляемость,
          отеки лодыжек и признаки (повышенное давление в яремной вене, «ритм
          галопа», смещение верхушечного толчка влево), вызванные нарушением
          структуры и/или функции сердца<sup>1</sup><br /><br />
          Мы подготовили для Вас удобный чек-лист для выявления пациентов с СН,
          который поможет вовремя направить его к ВРАЧУ-КАРДИОЛОГУ или ТЕРАПЕВТУ
        </div>
        <a
          :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/pod_prikrutiem/pod-prikrytiem.pdf`"
          target="_blank"
          class="diagnostic-block__button button button_white"
        >
          <svg
            class="mr-2"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5 2.25C12.9142 2.25 13.25 2.58579 13.25 3V13.1893L16.9697 9.46967C17.2626 9.17678 17.7374 9.17678 18.0303 9.46967C18.3232 9.76256 18.3232 10.2374 18.0303 10.5303L13.0303 15.5303C12.9584 15.6022 12.8755 15.6565 12.7871 15.6931C12.6987 15.7298 12.6017 15.75 12.5 15.75C12.3983 15.75 12.3013 15.7298 12.2129 15.6931C12.1245 15.6565 12.0416 15.6022 11.9697 15.5303L6.96967 10.5303C6.67678 10.2374 6.67678 9.76256 6.96967 9.46967C7.26256 9.17678 7.73744 9.17678 8.03033 9.46967L11.75 13.1893V3C11.75 2.58579 12.0858 2.25 12.5 2.25ZM3.5 14.25C3.91421 14.25 4.25 14.5858 4.25 15V19C4.25 19.3315 4.3817 19.6495 4.61612 19.8839C4.85054 20.1183 5.16848 20.25 5.5 20.25H19.5C19.8315 20.25 20.1495 20.1183 20.3839 19.8839C20.6183 19.6495 20.75 19.3315 20.75 19V15C20.75 14.5858 21.0858 14.25 21.5 14.25C21.9142 14.25 22.25 14.5858 22.25 15V19C22.25 19.7293 21.9603 20.4288 21.4445 20.9445C20.9288 21.4603 20.2293 21.75 19.5 21.75H5.5C4.77065 21.75 4.07118 21.4603 3.55546 20.9445C3.03973 20.4288 2.75 19.7293 2.75 19V15C2.75 14.5858 3.08579 14.25 3.5 14.25Z"
              fill="currentColor"
            />
          </svg>
          Скачать памятку
        </a>
      </div>
    </div>
    <div class="invite-block">
      <div class="content">
        <div class="invite-block__tag">Не пропустите</div>
        <div class="invite-block__title">
          Будьте в курсе cвежих выпусков <br />и пригласите коллег
        </div>
        <div class="invite-block__buttons">
          <div
            v-if="user && !Object.keys(user).length"
            class="invite-block__button button button_white"
            @click="openPopup"
          >
            Подписаться
          </div>
          <div class="invite-block__button button button_white" @click="copyLink">
            Пригласить коллегу
          </div>
        </div>
      </div>
    </div>
    <div class="video-block">
      <div class="content">
        <div class="video-block__row">
          <div class="video-block__image-container">
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/video_mob.jpg`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/video_tab.jpg`"
                media="(max-width: 1220px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/video.jpg`"
                media="(min-width: 1221px)"
              />
              <img
                class="video-block__image"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/video.jpg`"
              />
            </picture>
          </div>
          <div class="video-block__text">
            <div class="video-block__tag">Обращение главного эксперта</div>
            <div class="video-block__title">Какой-то заголовок</div>
            <div class="video-block__description">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel illum dolore eu
              feugiat nulla facilisis at vero eros et accumsan et iusto odio
              dignissim qui blandit praesent
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-block">
      <img
        class="news-block__steps"
        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/steps.svg`"
      />
      <div class="content">
        <div class="news-block__row">
          <div class="news-block__title">Новости</div>
          <div class="news-block__arrows" v-if="showArrows">
            <div
              class="news-block__arrow news-block__arrow_left"
              @click="$refs.newsSlider.prev()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
            <div
              class="news-block__arrow news-block__arrow_right"
              @click="$refs.newsSlider.next()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="news-block__slider">
          <VueSlickCarousel
            v-bind="newsSettings"
            ref="newsSlider"
            @init="onInitCarousel"
            @reInit="onInitCarousel"
          >
            <a
              href="https://az-most.ru/library/videomaterials/ot-klinicheskih-voprosov-k-marshrutizacii-pacientov-s-hsn"
              target="_blank"
              class="news-block__item"
            >
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/news1.jpg`"
                class="news-block__item-img"
              />
              <div class="news-block__item-title">
                От клинических вопросов к маршрутизации пациентов с ХСН
              </div>
              <div class="news-block__item-description">
                д.м.н., профессор кардиолог
              </div>
            </a>
            <a
              href="https://az-most.ru/library/videomaterials/sovremennye-kriterii-diagnostiki-snsfv?access=0073d0c1b2cb0baab7d0e0c9b124834f01d43d73804bfa0a21ae7972697cc2c5"
              target="_blank"
              class="news-block__item"
            >
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/news2.jpg`"
                class="news-block__item-img"
              />
              <div class="news-block__item-title">
                Современные критерии диагностики СНсФВ
              </div>
              <div class="news-block__item-description">
                д.м.н. эндокринолог
              </div>
            </a>
            <a
              href="https://az-most.ru/library/videomaterials/glotok-svezhego-vozduha-v-klinicheskih-rekomendaciyah-po-terapii-hsn?access=4e3bfdc11a4a8df66cd5fd2c99bf798dad8a04a5b300642e68ef7ec4e03cc374"
              target="_blank"
              class="news-block__item"
            >
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/pod_prikrutiem/news3.jpg`"
                class="news-block__item-img"
              />
              <div class="news-block__item-title">
                «Глоток свежего воздуха» в клинических рекомендациях по терапии
                ХСН
              </div>
              <div class="news-block__item-description">
                д.м.н., профессор кардиолог
              </div>
            </a>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <LibrarySlider
      v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
      :items="sliderItems"
      :color="'#2186AF'"
      :title="'Это может быть полезным'"
      @clickMaterials="clickMaterials"
    />
    <PopupEnd
      :eventName="'PodPrikrutiem'"
      :backUrl="{ name: 'PodPrikrutiem' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import VueSlickCarousel from "vue-slick-carousel";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  metaInfo() {
    return { title: "Под прикрытием" };
  },
  name: "PodPrikrutiem",
  components: {
    LibrarySlider,
    VueSlickCarousel,
    PopupEnd,
  },
  data: () => ({
    popup: false,
    showArrows: false,
    newsSettings: {
      slidesToShow: 3,
      draggable: false,
      infinite: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: false,
            variableWidth: true,
          },
        },
      ],
    },
    animValues: [
      {
        start: 0.0,
        finish: 5.5,
        step: 0.3,
        value: 0.0,
      },
      {
        start: 0,
        finish: 37,
        step: 1,
        value: 0,
      },
      {
        start: 0,
        finish: 26,
        step: 1,
        value: 0,
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "user",
      "TextList",
      "VideoList",
      "PodcastsList",
      "Projects",
    ]),
    sliderItems() {
      let arr = [];
      if (this.PodcastsList && this.PodcastsList.length) {
        arr = arr.concat(
          this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
        );
      }
      if (this.TextList && this.TextList.length) {
        arr = arr.concat(
          this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
        );
      }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" }))
        );
        // .filter((video) => video.slug === 'petunina-n-a-inglt-2-sravnenie-osnovnyh-predstavitelej-klassa')
      }
      if (this.Projects && this.Projects.length) {
        arr = arr.concat(
          this.Projects.map((el) => ({ ...el, component: "project" }))
        );
      }
      return arr;
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchProjects",
      "fetchAlerts",
    ]),
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "10years", {
          "10years": {
            "useful materials": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },

    async counterAnim(start = 0, finish = 10, step = 1, target) {
      for (let i = start; i <= finish; i += step) {
        target.value = await this.delayedLog(i, 50);
      }
      target.value = finish;
    },
    async delayedLog(item, duration = 300) {
      await this.delay(duration);
      return item;
    },
    async delay(duration = 300) {
      return new Promise((resolve) => {
        setTimeout(resolve, duration);
      });
    },
    observer() {
      const vm = this;
      const createObserver = () => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(async (entry) => {
              if (entry.isIntersecting) {
                const promises = this.animValues.map((el) => {
                  this.counterAnim(el.start, el.finish, el.step, el);
                });
                await Promise.all(promises);
              }
            });
          },
          {
            // процент пересечения целевого элемента с областью просмотра
            // 10%
            threshold: 0.7,
          }
        );

        // находим все секции
        const sections = document.querySelectorAll(".statistic-block");
        // начинаем за ними наблюдать
        sections.forEach((section) => observer.observe(section));
      };

      createObserver();
    },
    onInitCarousel() {
      this.showArrows =
        this.$refs.newsSlider?.settings.slidesToShow <
        this.$refs.newsSlider?.$slots.default.length;
    },
    closePopup() {
      this.popup = false;
    },
    openPopup() {
      this.popup = true;
    },
    copyLink() {
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "10years", {
          "10years": {
            "invite colleague": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
  },
  async mounted() {
    this.observer();
    await this.fetchTextMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchVideoMaterials({
      disease: ["severe_asthma"],
    });
    await this.fetchPodcasts({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchProjects({
      disease: ["hbp", "hsn", "diabetes"],
    });
  },
};
</script>

<style lang="scss" scoped>
.pod-prikrutiem {
  .button {
    width: fit-content;
  }

  .button_white {
    color: #3c4242;
  }

  sup {
    vertical-align: super;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: inherit;
    white-space: nowrap;
  }
}
.first-block {
  position: relative;
  min-height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 1220px) {
    min-height: 618px;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 48px;
    justify-content: flex-end;
    min-height: 446px;
  }

  &__img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  &__caption {
    width: 578px;
    max-width: 100%;

    & picture {
      display: block;
    }

    & img {
      max-width: 100%;
    }
  }

  &__description {
    margin-top: 32px;
    width: 515px;
    max-width: 100%;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    color: #d1d2d3;
    padding-bottom: 16px;
    border-bottom: 1px solid #f2b218;

    @media screen and (max-width: 767px) {
      margin-top: 0px;
      font-size: 18px;
    }
  }
}

.second-block {
  padding-bottom: 64px;
  min-height: 440px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    padding-top: 48px;
  }

  & .content {
    margin-top: auto;
  }
  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #d1d2d3;
    max-width: 1000px;
  }
}

.statistic-block {
  position: relative;
  z-index: 2;
  padding: 83px 0;
  color: #fff;
  background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/pod_prikrutiem/statistic-block.jpg");
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1220px) {
    background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/pod_prikrutiem/statistic-block_tab.jpg");
  }

  @media screen and (max-width: 767px) {
    padding: 48px 0;
    background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/pod_prikrutiem/statistic-block_mob.jpg");
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    max-width: 700px;

    &_highlight {
      animation: textHighlight 0.5s linear 2s both;
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__data {
    margin-top: 56px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    max-width: 345px;

    @media screen and (max-width: 1220px) {
      margin-top: 16px;
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &:not(:last-child) {
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 37px;
          right: -16px;
          display: block;
          background-color: #fff;
          content: "";
        }
      }

      &-value {
        font-family: "Roboto Slab", sans-serif;
        font-size: 48px;
        font-weight: 400;
        line-height: 62px;
        margin-bottom: 16px;

        @media screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      &-label {
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  &__link {
    display: inline-block;
    margin-top: 56px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    padding-bottom: 4px;
    border-bottom: 1px solid #f2b218;

    @media screen and (max-width: 767px) {
      margin-top: 32px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__video {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 384px;

    @media screen and (max-width: 1220px) {
      position: static;
      transform: none;
    }

    @media screen and (max-width: 767px) {
      width: 300px;
    }
  }
}

.diagnostic-block {
  padding: 64px 0;
  background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/pod_prikrutiem/block4.jpg");
  color: #fff;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1220px) {
    background-image: none;
  }

  &__image {
    display: none;

    @media screen and (max-width: 1220px) {
      width: 100%;
      height: auto;
      margin-bottom: 32px;
      display: block;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 32px;
    max-width: 60%;

    @media screen and (max-width: 1220px) {
      max-width: none;
    }
  }

  &__button {
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.invite-block {
  padding: 180px 0;
  background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/pod_prikrutiem/block5.jpg");
  color: #fff;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 767px) {
    padding-bottom: 48px;
  }

  &__tag {
    width: fit-content;
    margin: 0 auto 32px;
    padding: 4px 8px;
    border-radius: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    background-color: #f0ab00;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 32px;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__button {
    width: 236px !important;

    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
    }
  }
}

.video-block {
  padding: 48px 0;
  display: none;

  &__row {
    display: grid;
    grid-template-columns: 530px 1fr;
    column-gap: 40px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr;
      row-gap: 32px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &-container {
      width: 100%;
      height: auto;
      border-radius: 40px;
      overflow: hidden;
    }
  }

  &__text {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__tag {
    width: fit-content;
    margin: 0 0 32px;
    padding: 4px 8px;
    border-radius: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    background-color: #f0ab00;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
}

.news-block {
  position: relative;
  padding: 48px 0;
  color: #fff;

  &__slider {
    margin: 0 -15px;
  }

  &__steps {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    margin-right: 30px;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ebefee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #2186af;
    transition: 0.3s;

    :hover {
      background-color: #d8dada;
      color: #8b8e8e;
    }
    &_left {
      margin-right: 32px;
    }
  }

  &__item {
    height: 500px;
    width: auto !important;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    padding: 24px;
    margin: 0 15px;

    @media screen and (max-width: 1220px) {
      padding: 0;
      height: auto;
      border-radius: 0;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @media screen and (max-width: 1220px) {
        height: 270px;
        position: static;
        border-radius: 30px;
      }
    }

    &-title {
      position: relative;
      z-index: 2;
      margin-top: auto;
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;

      @media screen and (max-width: 1220px) {
        margin-top: 16px;
      }
    }

    &-description {
      position: relative;
      z-index: 2;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #b2b4b4;
    }
  }
}

@keyframes textHighlight {
  to {
    text-shadow: 0px 0px 20.8px #faff00;
  }
}
</style>

<style lang="scss">
.news-block .slick-slide {
  // margin: 0 15px;
  // width: calc(33.33333% - 30px);

  @media screen and (max-width: 1220px) {
    width: auto;
  }

  @media screen and (max-width: 767px) {
    width: 280px;
  }
}
</style>